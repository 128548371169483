import React from 'react'
import styled from 'styled-components'
import layout from '../../services/layout';
import Link from 'next/link';
import Image from 'next/image';
import AnalyticEventService from '../../services/AnalyticEventService';
import FooterPortal from '../furniture/FooterPortal';
import { Button } from 'semantic-ui-react';

const HeroContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: end;
  justify-content: space-evenly;
  gap: 20%;

  @media (max-width: ${layout.global.mobileMax}) {
    gap: 10px;
  }

  h2 {
    font-size: 24px;
    font-weight: normal;
  }
`

const BackgroundImage = styled(Image)`
  filter: brightness(60%);
  object-fit: cover;
  object-position: center;

  &.mobile-only {
    display: none;
  }
  @media (max-width: ${layout.global.mobileMax}) {    
    &.desktop-only {
      display: none;
    }
    &.mobile-only {
      display: block;
    }
  }
`

const HeroActionContainer = styled(Link)`
  z-index: 1;
  cursor: pointer;
  &, &:hover, &:active {
    color: white;
  }
  
  margin-bottom: 30px;
  @media (max-width: ${layout.global.mobileMax}) {
    margin-bottom: 20px;
  }

  h2 {    
    background: #2e2e2e;
    padding: 10px 30px;
    border-radius: 100px;
    text-align: center;
    line-height: 42px;
    box-shadow: 0 0 5px 2px rgba(218,33,40) !important;
  }
`

function HeroAction({ title, url }) {
  return <HeroActionContainer href={url}>
    <h2>{title}</h2>
  </HeroActionContainer>
}

const HeroFullWidthClickAreaContainer = styled.a`
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 1;

  &:first-child {
    top: 0;
  }
  &:last-child {
    bottom: 0;
  }
`

function HeroFullWidthClickArea({ takeOver, position }) {
  const isExternal = !takeOver.ctaUrl.startsWith('https://www.my105.com/');
  return <HeroFullWidthClickAreaContainer
    href={takeOver.ctaUrl}
    target={isExternal && "_blank"}
    rel={isExternal && "noopener noreferrer"}
    onClick={() => {
      AnalyticEventService.createAnalyticEvent({
        eventType: 'HOMEPAGE_TAKEOVER',
        metadata: {
          action: 'click',
          position: position,
          advertiser: takeOver.advertiser
        }
      });

    }}
  >
    {takeOver.ctaText && position === 'bottom' && <div style={{ textAlign: 'center', color: 'white', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>
      {takeOver.ctaText}
    </div>}
  </HeroFullWidthClickAreaContainer>
}

function RoadOrRaceHero({ takeOver }) {
  return <HeroContainer>
    {takeOver
      ? <>
        <HeroFullWidthClickArea takeOver={takeOver} position='top' />
        <BackgroundImage src={takeOver.desktop} fill className='desktop-only' />
        <BackgroundImage src={takeOver.mobile} fill className='mobile-only' />
        <HeroFullWidthClickArea takeOver={takeOver} position='bottom' />
      </>
      : <>
        <BackgroundImage src='/homepage-bg/road-or-race.jpg' fill alt='Road or Race' priority />
        <HeroAction title='Road' url='/search/performance?source=HOME_RACE_OR_ROAD' />
        <HeroAction title='Race' url='/search/motorsport?source=HOME_RACE_OR_ROAD' />
      </>
    }
    <FooterPortal>
      <small>Homepage image used with permission from WhichCar.com.  Photographer: Christian Brunelli.</small>
    </FooterPortal>
  </HeroContainer>
}

export default RoadOrRaceHero
