import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import {
  Button,
  Form,
  Input,
  Select,
  Loader,
  Dimmer,
  Message,
  Icon
} from 'semantic-ui-react'
import PriceRangeCombobox from '../Search/PriceRangeCombobox'
import search from '../../services/search'
import Link from 'next/link'
import { useDebouncedCallback } from 'use-debounce';
import layout from '../../services/layout'
import { hasCookie, getCookie } from 'cookies-next';
import AnalyticEventService from '../../services/AnalyticEventService';
import { useRouter } from 'next/router';
import _ from 'lodash';
import RoadOrRaceHero from './RoadOrRaceHero'

const SearchMaskContainer = styled.div`
  text-align: center;
  display: block;
`

const HeroContainer = styled.div`
  position: relative;
  padding: 100px 20px;
  color: white;

  min-height: 550px;
  @media (max-width: ${layout.global.mobileMax}) {
    min-height: 450px; 
  }
  
  .ui.form {
    .search-buttons-row {
      margin: 30px 0 0 0;
      .field {
        text-align: center;
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  &.is-masking-search-widget {
    padding-top: 220px;
  }

  &.is-take-over-active {
    min-height: 700px;
    padding-top: 200px;
    @media (max-width: ${layout.global.largeMax}) {
      min-height: 550px;
    }
    @media (max-width: ${layout.global.mobileMax}) {
      padding-top: 30px;
      padding-bottom: 80px;
    }
  }

  .button {
    font-family: 'Montserrat-Regular', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  }
  input {
    font-family: 'Montserrat-Regular', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  }

  h1 {
    margin: 0 0 20px 0;
    text-align: center;
  }
  
  @media (max-width: ${layout.global.smallMax}) {
    padding: 20px 10px 100px 10px;
    .ui.form {
      .search-buttons-row {
        margin: 20px 0 0 0;
        .field {
          &:last-child {
            text-align: center;
          }
        }
      }
    }
  }
  
  @media (max-width: ${layout.global.mobileMax}) {
    h1 {
      font-size: 24px;
    }
  }
  
  @media (max-width: 400px) {
    h1 {
      font-size: 20px;
    }
  }
  
  @media (max-width: 350px) {
    h1 {
      font-size: 18px;
    }
  }
`

const SearchBox = styled.div`
  max-width: ${layout.home.searchWidgetMaxWidth};
  margin: 0 auto;
  background-color: rgba(30, 30, 30, 0.5);
  border-radius: 4px;
  padding: 30px 40px;
  backdrop-filter: blur(5px);  
  -webkit-backdrop-filter: blur(5px);
  z-index: 2;
  position: relative;

  @media only screen and (max-width: 599px) {
    max-width: 100%;
  }

  .ui.selection.dropdown .menu {
    max-height: 16rem;

    .item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  @media (max-width: ${layout.global.mobileMax}) {
    padding: 30px 20px;
  }
`

const PriceSelectContainer = styled.div`
  display: flex;

  .spacer {
    display: inline-block;
    width: 20px;
  }

  > label {
    display: none;
  }
`

const SearchButton = styled(Button)`
  min-width: 220px;
  padding: 15px 30px !important;
  background-color: ${layout.colors.primary} !important;
  color: white !important;

  @media (max-width: ${layout.global.mobileMax}) {
    width: initial;
  }
`

const ErrorMessage = styled.div`
  margin: 20px auto 0 auto;
  max-width: 500px;
`

const FindCategory = (categories, key) => {
  let result;
  for (let category of categories) {
    if (category.key === key) {
      result = category;
    }
    if (!result && category.children) {
      result = FindCategory(category.children, key);
    }

    if (result) {
      return result;
    }
  }
}

const NoWrap = styled.span`
  white-space: nowrap;
`

function PriceSelect({ priceRange, setPriceRange }) {
  return <PriceSelectContainer>
    <PriceRangeCombobox
      label='Minimum Price'
      value={priceRange.minPrice}
      onChange={(value) => setPriceRange({
        price: {
          ...priceRange,
          minPrice: value
        }
      })}
      anyLabel='Minimum' />
    <div className="spacer" />
    <PriceRangeCombobox
      label='Maximum Price'
      value={priceRange.maxPrice}
      onChange={(value) => setPriceRange({
        price: {
          ...priceRange,
          maxPrice: value
        }
      })}
      anyLabel='Maximum' />
  </PriceSelectContainer>
}

const verticals = [
  {
    key: 'ALL',
    text: 'All',
    showMakeModel: true
  },
  {
    key: 'CAR',
    text: 'Cars, Bikes & Karts',
    showMakeModel: true
  },
  {
    key: 'TRAILER',
    text: 'Trailers, Transporters & Tow Vehicles',
    showMakeModel: false
  },
  {
    key: 'PART',
    text: 'Parts & Accessories',
    showMakeModel: false
  }
]
const MapRefinementHierarchy = (items, results = [], level = 0) => {
  items.forEach(item => {
    results.push(
      {
        text: <>
          {[...Array(level).keys()].map((_, index) => <span key={'spacer-' + index}>&nbsp;&nbsp;&nbsp;&nbsp;</span>)}
          {item.text}
        </>,
        key: item.key,
        value: item.key
      }
    );
    if (item.children && item.children.length > 0) {
      MapRefinementHierarchy(item.children, results, level + 1);
    }
  });
  return results;
}

const MAKES_TO_BOOST = ['Tesla', 'Wolf'];

const STORED_REFINEMENTS_KEY = 'Home_SearchWidgetRefinements';
const IS_SHOWING_SEARCH_WIDGET_KEY = 'Home_IsShowingSearchWidget';
const INITIAL_REFINEMENTS = {
  vertical: 'ALL',
  category: 'ALL',
  make: 'ALL',
  model: 'ALL',
  keyword: '',
  location: 'ALL',
  includeSold: hasCookie('includeSold') ? getCookie('includeSold').toString() : 'true',
  price: {},
};

const tryBrowserStorageOrDefault = (func, defaultValue, failedMessage) => {
  try {
    if (typeof window !== 'undefined') {
      const value = func();
      if (!_.isNil(value)) {
        return value;
      }
    }
  }
  catch (ex) {
    console.error(failedMessage, ex);
  }
  return defaultValue;
}

const TAKE_OVERS = [
  {
    start: '2024-04-26T01:00',
    end: '2024-04-29T03',
    advertiser: 'Young Timers Garage',
    desktop: '/homepage-bg/young-timers-garage-desktop.jpg',
    mobile: '/homepage-bg/young-timers-garage-mobile.jpg',
    ctaUrl: 'https://www.my105.com/search/keyword-28765%20OR%2028764',
    ctaText: 'Gen3 Prototypes now available exclusively via YTG'
  }
]

function SearchHero() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMaskingSearchWidget, setIsMaskingSearchWidget] = useState(true);
  const [refinementsResult, setRefinementResult] = useState({
    notInitialized: true,
    refinements: {
      categories: [],
      locations: [],
      makes: [],
      models: []
    },
    url: ''
  });
  const [refinementsQuery, setRefinementsQuery] = useState(
    () => tryBrowserStorageOrDefault(
      () => JSON.parse(localStorage.getItem(STORED_REFINEMENTS_KEY)),
      INITIAL_REFINEMENTS, 'failed to load homepage search refinements'
    )
  );
  const [keyword, setKeyword] = useState(
    () => tryBrowserStorageOrDefault(
      () => JSON.parse(localStorage.getItem(STORED_REFINEMENTS_KEY))?.keyword,
      '', 'failed to load homepage keyword refinement'
    )
  );
  const updateCount = useRef(0);
  const router = useRouter();

  const now = new Date().toISOString();
  const takeOver = TAKE_OVERS.find(x => x.start < now && x.end > now);

  const topMakesLimit = 20;
  const topMakes = refinementsResult.refinements.makes
    .slice(0, topMakesLimit).map(x => {
      return {
        key: x.key + ' Popular',
        value: x.value,
        text: x.text
      }
    });
  const boostedMakes = refinementsResult.refinements.makes.filter(x => MAKES_TO_BOOST.includes(x.key));
  boostedMakes.forEach(make => topMakes.push(make));
  topMakes.sort((a, b) => a.key.localeCompare(b.key));
  const allMakes = [...refinementsResult.refinements.makes];
  allMakes.sort((a, b) => a.key.localeCompare(b.key));

  const refreshRefinements = async (query) => {
    try {
      setIsError(false);
      setIsLoading(true);
      const refinements = await search.homepageRefinements(query);
      setRefinementResult(refinements);

      AnalyticEventService.createAnalyticEvent({
        eventType: 'HOMEPAGE_SEARCH',
        metadata: {
          request: query,
          response: {
            ...refinements,
            refinements: undefined
          },
          update: updateCount.current++
        }
      });

      return refinements;
    }
    catch (ex) {
      console.error('failed to update homepage widget refinements', query, ex);
      setIsError(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    refreshRefinements(refinementsQuery);
    setIsMaskingSearchWidget(
      tryBrowserStorageOrDefault(
        () => sessionStorage.getItem(IS_SHOWING_SEARCH_WIDGET_KEY) !== 'true',
        true, 'failed to load homepage search masking state'
      )
    );
  }, []);

  const updateRefinementQuery = async (updates) => {
    const updatedRefinements = {
      ...refinementsQuery,
      ...updates
    };
    setRefinementsQuery(updatedRefinements);
    setKeyword(updatedRefinements.keyword);

    tryBrowserStorageOrDefault(
      () => localStorage.setItem(STORED_REFINEMENTS_KEY, JSON.stringify(updatedRefinements)),
      null, 'failed to store homepage search refinements'
    );

    return await refreshRefinements(updatedRefinements);
  }

  const selectedCategory = FindCategory(refinementsResult.refinements.categories, refinementsQuery.category);
  const selectedCategoryVertical = selectedCategory && verticals.find(x => x.key === selectedCategory.vertical);
  const showMakeModel = selectedCategoryVertical && selectedCategoryVertical.showMakeModel;

  const debouncedUpdateRefinementQuery = useDebouncedCallback(
    update => updateRefinementQuery(update),
    1000
  );

  const zeroResults = refinementsResult.total === '0';

  return <HeroContainer className={(isMaskingSearchWidget ? 'is-masking-search-widget' : '') + (takeOver ? ' is-take-over-active' : '')}>
    <RoadOrRaceHero takeOver={takeOver} />
    <SearchBox>
      <h1>
        <NoWrap>Australia&apos;s #1 Performance &</NoWrap> <NoWrap>Motorsport Marketplace</NoWrap>
      </h1>
      {
        isMaskingSearchWidget && <SearchMaskContainer>
          <SearchButton onClick={() => {
            setIsMaskingSearchWidget(false);
            tryBrowserStorageOrDefault(
              () => sessionStorage.setItem(IS_SHOWING_SEARCH_WIDGET_KEY, 'true'),
              null, 'failed to store homepage search refinements'
            );
            AnalyticEventService.createAnalyticEvent({
              eventType: 'HOMEPAGE_SEARCH',
              metadata: {
                source: 'REVEAL'
              }
            });
          }}><Icon name='search' /> Search</SearchButton>
        </SearchMaskContainer>
      }
      {!isMaskingSearchWidget && <Form inverted>
        <Dimmer active={refinementsResult.notInitialized} style={{ background: 'transparent' }}>
          <Loader />
        </Dimmer>
        <Form.Group widths='equal' style={{ opacity: refinementsResult.notInitialized ? 0 : 1 }}>
          <Form.Field
            label='Category'
            control={Select}
            value={refinementsQuery.category}
            options={MapRefinementHierarchy(refinementsResult.refinements.categories)}
            onChange={(_, data) => {
              updateRefinementQuery({ category: data.value })
            }}
          >
          </Form.Field>
          {showMakeModel
            ? <>
              <Form.Field
                control={Select}
                label='Make'
                value={refinementsQuery.make}
                options={[
                  {
                    key: 'ALL',
                    value: 'ALL',
                    text: 'Any Make'
                  },
                  {
                    key: 'POPULAR_HEADER',
                    value: 'Popular Makes',
                    text: 'Popular Makes',
                    disabled: true
                  },
                  ...topMakes,
                  {
                    key: 'ALL_HEADER',
                    value: 'All Makes',
                    text: 'All Makes',
                    disabled: true
                  },
                  ...allMakes
                ]}
                onChange={(_, data) => {
                  updateRefinementQuery({ make: data.value })
                }}
              />
              <Form.Field
                control={Select}
                label='Model'
                value={refinementsQuery.model}
                options={[
                  {
                    key: 'ALL',
                    value: 'ALL',
                    text: 'Any Model'
                  },
                  ...refinementsResult.refinements.models
                ]}
                disabled={refinementsResult.refinements.models.length === 0}
                onChange={(_, data) => {
                  updateRefinementQuery({ model: data.value })
                }}
              />
            </>
            : <Form.Field
              control={Input}
              label='Keyword'
              value={keyword}
              onChange={(_, data) => {
                setKeyword(data.value);
                debouncedUpdateRefinementQuery({ keyword: data.value })
              }}
              placeholder='What are you looking for?'
            />}
        </Form.Group>
        <Form.Group widths='equal' style={{ opacity: refinementsResult.notInitialized ? 0 : 1 }}>
          <Form.Field
            control={Select}
            label='Location'
            placeholder='Choose location'
            value={refinementsQuery.location}
            options={MapRefinementHierarchy(refinementsResult.refinements.locations)}
            onChange={(_, data) => {
              updateRefinementQuery({ location: data.value })
            }}
          />
          {showMakeModel && <Form.Field
            control={Input}
            label='Keyword'
            value={keyword}
            onChange={(_, data) => {
              setKeyword(data.value);
              debouncedUpdateRefinementQuery({ keyword: data.value })
            }}
            placeholder='What are you looking for?'
          />}
          <Form.Field
            control={PriceSelect}
            label='Price'
            priceRange={refinementsQuery.price}
            setPriceRange={(changes) => updateRefinementQuery(changes)}
          />
        </Form.Group>
        <Form.Group widths='equal' className='search-buttons-row'>
          <Form.Field></Form.Field>
          <Form.Field>
            <Link href={'/search/' + refinementsResult.url + '?source=HOMEPAGE_SEARCH'} style={{ color: 'white', display: 'inline-block' }}
              onClick={async (e) => {
                e.preventDefault();
                let newRefinements = refinementsResult;
                if (keyword !== refinementsQuery.keyword) {
                  newRefinements = await updateRefinementQuery({ keyword: keyword });
                }
                const url = '/search/' + newRefinements.url + '?source=HOMEPAGE_SEARCH';
                router.push(url);
              }}>
              <SearchButton
                type='submit'
                disabled={zeroResults || isLoading}
                fluid>
                {zeroResults
                  ? 'No results found'
                  : refinementsResult.total !== undefined ? ('Show me ' + refinementsResult.total + ' results') : <>&nbsp;</>}
              </SearchButton>
            </Link>
          </Form.Field>
          <Form.Field>
            <Button
              basic
              inverted
              icon='delete'
              onClick={() => updateRefinementQuery(INITIAL_REFINEMENTS)}
              content='Clear All'
            />
          </Form.Field>
        </Form.Group>

        {zeroResults && <p style={{ color: 'white', textAlign: 'center' }}>Your search returned zero results.  Try adjusting your criteria.</p>}
        {isError && <ErrorMessage>
          <Message icon negative>
            <Icon name='exclamation triangle' />
            <Message.Content>
              <Message.Header>Sorry, something went wrong.</Message.Header>
              Please try again, we&apos;ll fix this as soon as we can.
            </Message.Content>
          </Message>
        </ErrorMessage>
        }
      </Form>}
    </SearchBox>
  </HeroContainer >
}

export default SearchHero
